import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import SEO from "./seo"
import Icons from "./icons"

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
`

const Layout = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      file(
        relativePath: { eq: "logo/logo-with-text/vector/logo-with-text.svg" }
      ) {
        extension
        publicURL
      }
    }
  `)

  return (
    <>
      <main
        style={{
          margin: `0 auto`,
          marginTop: "50px",
          maxWidth: 600,
          paddingTop: 0,
        }}
      >
        <SEO title="Home" />
        {!data.file.childImageSharp && data.file.extension === "svg" ? (
          <img src={data.file.publicURL} alt="logo" />
        ) : null}

        <Icons />
      </main>
      <Footer>© {new Date().getFullYear()}, Mantas Astrauskas</Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
