import React from "react"
import styled from "styled-components"
import { config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

config.autoAddCss = false

const Body = styled.div`
  margin-top: -35%;
  display: flex;
  justify-content: center;
  a {
    padding: 0.6rem;
    color: black;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;

    &:hover,
    &:focus {
      color: #951dd9;
    }
  }
`

const Icons = () => (
  <Body>
    <a href="https://github.com/mantasastra">
      <FontAwesomeIcon icon={faGithub} size="2x" />
    </a>
    <a href="https://www.linkedin.com/in/mastrauskas4/">
      <FontAwesomeIcon icon={faLinkedin} size="2x" />
    </a>
    <a href="https://www.instagram.com/mantasastra">
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>
    <a href="https://twitter.com/mantasastra">
      <FontAwesomeIcon icon={faTwitter} size="2x" />
    </a>
  </Body>
)

export default Icons
