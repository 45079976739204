import React from "react"
import Layout from "../components/layout"

class IndexPage extends React.Component {
  constructor() {
    super()

    this.state = { isLoading: true }
  }

  componentDidMount() {
    this.setState({ isLoading: false })
  }

  render() {
    return this.state.isLoading ? null : <Layout />
  }
}

export default IndexPage
